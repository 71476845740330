<template>
  <div class="card p-shadow-6" :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Loading
      :active.sync="loading"
      :isFullPage="false"
      :zIndex="9000"
    ></Loading>
    <h1>Resumo dos Inquéritos de Satisfação</h1>
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-3 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="year"
          class="p-col-10"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <div class="p-col-12 p-md-6" v-if="installationsResume">
        <h4>Instalações</h4>
        <p class="p-ml-4"><b>1 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ installationsResume["1T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{ installationsResume["1T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>2 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ installationsResume["2T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{ installationsResume["2T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>3 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ installationsResume["3T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{ installationsResume["3T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>4 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ installationsResume["4T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{ installationsResume["4T"]["avgTotalPerc"] }}%
        </p>
      </div>

      <div class="p-col-12 p-md-6" v-if="supportResume">
        <h4>Suporte</h4>
        <p class="p-ml-4"><b>1 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ supportResume["1T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b> {{ supportResume["1T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>2 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ supportResume["2T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b> {{ supportResume["2T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>3 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ supportResume["3T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b> {{ supportResume["3T"]["avgTotalPerc"] }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>4 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{ supportResume["4T"]["totalAwsweres"] }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b> {{ supportResume["4T"]["avgTotalPerc"] }}%
        </p>
      </div>

      <div class="p-col-12 p-md-6" v-if="supportResume">
        <divider />
        <h4>Instalações e Suporte</h4>
        <p class="p-ml-4"><b>1 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{
            supportResume["1T"]["totalAwsweres"] +
            installationsResume["1T"]["totalAwsweres"]
          }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{
            (
              (supportResume["1T"]["avgTotalPerc"] +
                installationsResume["1T"]["avgTotalPerc"]) /
              2
            ).toFixed(3)
          }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>2 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{
            supportResume["2T"]["totalAwsweres"] +
            installationsResume["2T"]["totalAwsweres"]
          }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{
            (
              (supportResume["2T"]["avgTotalPerc"] +
                installationsResume["2T"]["avgTotalPerc"]) /
              2
            ).toFixed(3)
          }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>3 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{
            supportResume["3T"]["totalAwsweres"] +
            installationsResume["3T"]["totalAwsweres"]
          }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{
            (
              (supportResume["3T"]["avgTotalPerc"] +
                installationsResume["3T"]["avgTotalPerc"]) /
              2
            ).toFixed(3)
          }}%
        </p>
        <divider />
        <p class="p-ml-4"><b>4 º Trimestre</b></p>
        <p class="p-ml-6">
          <b>Número de Inquéritos Respondidos: </b>
          {{
            supportResume["4T"]["totalAwsweres"] +
            installationsResume["4T"]["totalAwsweres"]
          }}
        </p>
        <p class="p-ml-6">
          <b>Percentagem Total: </b>
          {{
            (
              (supportResume["4T"]["avgTotalPerc"] +
                installationsResume["4T"]["avgTotalPerc"]) /
              2
            ).toFixed(3)
          }}%
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import satisfactionFormService from "../services/satisfactionForm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "FormsResume",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear(),
      supportResume: null,
      installationsResume: null,
    };
  },
  async created() {
    await this.getResume();
  },
  methods: {
    yearChanged(year) {
      if (year == this.year) {
        return;
      }
      this.year = year;
      return this.getResume();
    },
    getResume() {
      this.loading = true;
      return satisfactionFormService.getResume(this.year).then((response) => {
        this.supportResume = response.support;
        this.installationsResume = response.installations;
        return (this.loading = false);
      });
    },
  },
};
</script>
